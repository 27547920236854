.ordr-nav {
    background-color: #f93822;
    height: 80px;
    padding: 0 7%;
}

.navbar-toggler {
    border: none; 
    color: #fff;
}

.navbar-nav {
    background-color: #f93822;
    padding: 18px;
    z-index: 200;
    position: relative;
}

.nav-links {
    font-family: 'Muli', sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: #fff !important;
    text-transform: uppercase;
    padding: 0.5rem 20px !important;

    &.small-text {
        font-size: 13px
    }
}

.ordr-arrow {
    color: #fff;
    position: relative;
    right: 13px;
}


@media screen and (min-width: 992px) {
    .v-center-bg {
        display: flex;
        align-items: center;
    }
}

@media screen and (max-width: 992px) {
    .navbar-nav {
        padding: 20px !important;
    }

    .ordr-arrow {
        display: none;
    }
}

@media screen and (max-width: 1000px) {
    .ordr-nav {
        padding: 0 1%;
    }
}

@media screen and (max-width: 1084px) {
    .navbar-nav {
        padding: 0;
    }
}


