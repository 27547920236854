
.sub-footer {
    height: 250px; 
    background-image: url('../assets/ordrslip-background.png');
    background-position: center;
    background-size: cover;
    padding: 50px 40px;
}

.footer-logo {
    height: 40px;
}

.logo-centered {
    display: flex;
    justify-content: center;
}

.social-row {
    padding-top: 20px;
}

.social-icon {
    background-color: #fff;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin: 4px 0 0 -2px;
    box-shadow: none;
    border: 1px solid #fff;
    color: #000;

    &:hover {
        color: #000;
        background-color: #eee;
        border: 1px solid #fff;
        box-shadow: none;
    }

    &:focus {
        color: #000;
        background-color: #eee;
        border: 1px solid #fff;
        box-shadow: none;
        outline: none;
    }

    &:active {
        color: #000;
        background-color: #eee !important;
        border: 1px solid #fff;
        box-shadow: none;
    }
}

.social-icons {
    font-size: 20px;
    position: relative;
    top: 8px;
    left: 10px;

    &.facebook {
        top: 8px;
        left: 12px;
    }
}

// Footer 

.footer {
    min-height: 50px;
    background-color: #000;
}

.footer-txt {
    color: #fff !important;
    font-family: 'Muli', sans-serif;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0;
}

.v-center-col {
    align-items: center;
    display: flex;
}

.h-center-col {
    display: flex;
    justify-content: center;
}

.height-100 {
    height: 100% !important;
    min-height: 50px;
}

@media screen and (min-width: 1340px) {
    .sub-footer {
        padding: 50px 20%;
    }
}