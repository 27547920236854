.hero {
    background-image: url('../assets/splash.png');
    height: auto;
    background-position: center;
    background-size: cover;
    text-align: center;
    padding: 100px 15px;
    position: relative;
}

.hero::before{
    background: #00000030;
    content: '';
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
}

.community-header {
    font-family: 'Muli', sans-serif;
    font-weight: 800;
    font-size: 40px;
    color: #fff;
}

.sub-community-header {
    font-family: 'Muli', sans-serif;
    font-size: 16px;
    color: #fff;
    padding: 20px;
}

.take-out-hero {
    font-family: 'Muli', sans-serif;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 800;
}

.city-logo {
    height: 200px;
}

// Search

.search-bar {
    padding-top: 20px;
}

.search {
    height: 40px;
    width: 100%;
}

.search-btn {
    background-color: #000;
    color: #fff;
    height: 50px;
    border-radius: 0;
    margin: 0 10px; 
    width: 100%;
    border: #000;
    font-size: 15px;
    font-weight: 600; 
    text-transform: uppercase;

    &:hover {
        background-color: #000;
        border: 1px solid #000;
    }

    &:focus {
        opacity: .8;
        background-color: #000;
        border: 1px solid #000;
        box-shadow: none;
        outline: none;
    }

    &:focus-within {
        opacity: .8;
        background-color: #000;
        border: 1px solid #000;
        box-shadow: none;
        outline: none;
    }

    &:active {
        box-shadow: none;
        background-color: #000 !important;
        border: 1px solid #000 !important;
    }
}

.loc-icon {
    position: absolute;
    z-index: 100;
    left: 25px;
    top: 16px;
}
