@import url('https://fonts.googleapis.com/css?family=Muli:400,700,800,900&display=swap');
@import "~bootstrap/scss/bootstrap";
@import "./nav.scss";
@import "./hero.scss";
@import "./cities.scss";
@import "./home.scss";
@import "./footer.scss";
@import "./search.scss";
@import "./rest-info.scss";
@import "./resources.scss";

// Sitewide styles
html,body {
    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

.pin {
    width: 34px;
    height: 34px;
    border-radius: 50% 50% 50% 0;
    background: #000;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
}

.pin:after {
    content: "";
    width: 24px;
    height: 24px;
    margin: 5px 0 0 5px;
    transform: rotate(45deg);
    position: absolute;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
}


.ordr-btn {
    background-color: #fff; 
    border-radius: 40px;
    color: #f93822 !important;
    font-family: 'Muli', sans-serif;
    font-weight: bold;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #6B6C6C4D;
    text-transform: uppercase;
    font-size: 16px;
    padding: 0.5rem 20px !important;

    &.nav {
        padding: 0.5rem 40px !important;
    }

    &.learn {
        position: relative;
        top: -30px;
    }

    &:hover {
        background-color: #eee;
        border: 1px solid #6B6C6C4D;
        box-shadow: none;
    }

    &:focus {
        background-color: #eee;
        border: 1px solid #6B6C6C4D;
        box-shadow: none;
        outline: none;
    }

    &:active {
        background-color: #eee !important;
        border: 1px solid #6B6C6C4D;
        box-shadow: none;
    }
}

a {
    cursor: pointer !important;
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 400px;
}

.page-contain {
    padding: 0 7%;
}

.cities-header {
    padding: 40px 0 20px 0;
}

.bold {
    font-weight: 600;
}

.header {
    font-family: 'Muli', sans-serif;
    font-weight: bold;
    font-size: 26px;
    color: #000;
    text-transform: uppercase;
}

.subheader {
    font-family: 'Muli', sans-serif;
    font-size: 24px;
    color: #000;
}

.header-btn {
    font-family: 'Muli', sans-serif;
    font-weight: bold;
    font-size: 15px;
    color: #f93822;
    text-transform: uppercase;
    text-align: right;
}

.list-group {
    height: 360px;
}

.list-group-item {
    font-family: 'Muli', sans-serif;
    font-size: 16px;
    height: 40px;
    border: none;
    padding: 0;
}

.cities-btn {
    display: flex;
    justify-content: center;
    padding: 30px;
}

.right {
    float: right;
}

.left {
    float: left;
}

.city {
    color: #000; 

    &:hover {
        color: #000;
        font-weight: 500;
    }
}

@media screen and (max-width: 600px) {
    .community-header {
        font-size: 24px;
        padding: 20px;
        text-align: center;
    }

    .sub-community-header {
        font-size: 16px;
        text-align: center;
        padding-bottom: 20px;
    }

    .google-places-autocomplete__input {
        font-size: 14px;
    }

    .resource-header {
        font-size: 20px;
    }

    .resource-subheader {
        font-size: 16px;
    }

    .search-bar {
        padding: 0 36px;
    }

    .search-btn {
        width: calc(100% - 20px);
        font-size: 14px;
    }

    .city-logo {
        height: 150px;
    }

  }