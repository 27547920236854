
// Resource page
.location-box {
    height: 140px;
    border: 1px solid #AAA8A8;
    margin: 10px 0;
}

.location-img {
    height: 110px;
    width: 110px;
    border-radius: 15px;
    object-fit: cover;
    border: 1px solid #eee;
}

.scroll {
    height: 700px;
    overflow: scroll;
    border: 1px solid #DEDEDE;
    min-width: 500px;
    max-width: 550px;
}

.no-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.icon-contain {
    display: flex;
    align-items: center;
    padding: 10px;
    position: absolute;
    width: 100%;
    bottom: 10px;
}

.tag-contain {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    margin-left: -5px;
}

.tag {
    padding: 0 5px;
    font-size: 12px; 
}

.tags{
    position: absolute;
    right: 30px;
    padding: 0 10px;
    margin-top: 10px;
    background-color: #E3E3E3;
}

.loc-tag {
    text-align: center;
    font-family: 'Muli', sans-serif;
    font-size: 12px;
    color: #363434;
    min-width: 100px;
}

.border {
    border-top: 1px solid #000;
}

.download {
    font-family: 'Muli', sans-serif;
    font-weight: bold;
    font-size: 12px;
    color: #B4B4B4;
    text-transform: uppercase;
}

.logo-img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}

.apple {
    width: 89%;
}

.google {
    width: 100%;
}

.loc-detail {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 300px;
}

.loc-name {
    font-family: 'Muli', sans-serif;
    font-weight: bold;
    font-size: 18px;
}

.loc-address {
    font-family: 'Muli', sans-serif;
    font-size: 13px;
}

.resource-footer {
    background-color: #000;
    height: 250px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    margin: 50px -16px;
}

.resource-header {
    font-family: 'Muli', sans-serif;
    font-weight: 800;
    font-size: 26px;
    color: #fff;
    text-align: center;
    padding-top: 25px;
}

.resource-subheader {
    font-family: 'Muli', sans-serif;
    font-size: 20px;
    color: #fff;
    text-align: center;
}

.learn-btn {
    font-family: 'Muli', sans-serif;
    font-size: 24px;
    color: #f93822;       
    text-align: center;
    margin-bottom: 27px;

    &:hover {
        color: #f93822;
    }
}

.map-contain {
    padding-top: 40px;
    padding-bottom: 20px;
}

.map-styles {
    border: 1px solid #707070;
}

.gutter {
    padding-left: 30px !important;
}

.hidden-map {
    position: absolute;
    z-index: 100;
    width: 100%;
}

.map-toggle-btn {
    font-family: 'Muli', sans-serif;
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 10px;
}

.food-icon {
    color: #fff;
    position: absolute;
    right: -3px;
    top: -8px;
    font-size: 15px;
}

.map-name {
    position: absolute;
    top: 19px;
    left: -24px;
    font-weight: 700;
}

.no-locs-body {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.no-locations-head {
    font-size: 30px;
    text-align: center;
    max-width: 400px;
    padding-bottom: 20px;
}

.no-locations-subhead {
    font-size: 15px;
    text-align: center;
    font-weight: 600;
    color: #f93822;
    text-transform: uppercase;

    &:hover {
        color: #f93822;
    }
}

@media screen and (min-width: 770px) {
    .small-map {
        display: none;
    }
}

@media screen and (max-width: 770px) {
    .gutter {
        display: none;
    }

    .scroll { 
        max-width: 100%;
        min-width: 100%;
    }
}

@media screen and (max-width: 563px) {
    .gutter {
        display: none;
    }

    .logo-img {
        padding: 0 5px;
    }

    .location-img {
        height: 90px;
        width: 90px;
    }

    .scroll { 
        max-width: 100%;
        min-width: 100%;
    }

    .loc-detail {
        padding: 15px 5px;
        position: absolute;
        width: 61%;
        left: 115px;
        top: 27px;

        .loc-name {
            font-size: 16px;
        }
    }
}