.home-icon {
    height: 150px;
}

.home-info {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 30px;
}

.selection-contain {
    padding: 40px 0 50px 0;
    border-bottom: 1px solid #000;
}

.selection-text {
    font-family: 'Muli', sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: #000;
    padding: 10px;
    max-width: 180px;
}

.selection-sub-text {
    font-size: 14px;
    color: #000;
    padding: 10px;
    max-width: 250px;
}

.step {
    font-family: 'Muli', sans-serif;
    font-weight: 900;    
    font-size: 25px;
    text-align: center;
    color: #F93822;
}