.small-hero {
    background-image: url('../assets/splash.png');
    height: auto;
    background-position: center;
    background-size: cover;
    position: relative;
    text-align: center;
    padding: 50px 15px;
}

.small-hero::before{
    background: #00000030;
    content: '';
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
}

.rest-info-back {
    background: transparent;
    border: 1px solid #fff;
    border-radius: 24px;
    font-size: 15px;
    padding: 8px 30px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    margin-top: 11px;
}

.rest-info {
    align-items: center;
    padding: 40px 0;
}

.rest-info-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.rest-info-icon {
    max-width: 140px;
    display: flex;
    align-items: center;
}

.rest-info-contain {
    padding: 0 15%;
    font-family: 'Muli', sans-serif;
}

.rest-info-header {
    font-weight: 700;
    font-size: 30px;
    color: #000000;
}

.tags-contain {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    margin-left: -5px;
}

.rest-info-tags { 
    padding: 1px 24px;
    margin: 5px;
    background-color: #E3E3E3;
    text-align: center;
    font-size: 12px;
    color: #363434;
}

.rest-info-pickup {
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 50px;
}

.social-pad {
    padding: 20px 15px;    
    max-width: 340px;
    min-width: 250px;
}

.rest-info-box {
    border: 1px solid #C1C1C1;
    height: auto;
    width: 100%;
    padding: 20px;
}

.rest-info-name {
    font-weight: 700;
    color: #F93822;
    font-size: 17px;
    padding: 10px 0;
}

.rest-info-title {
    font-weight: 700;
    color: #6B6C6C;
    font-size: 15px;
}

.rest-info-details {
    color: #6B6C6C;
    font-size: 15px;

    &:hover {
        color: #6B6C6C;
    }
}

.rest-info-pad {
    padding-top: 15px;
}

.rest-info-download {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.rest-info-social-contain {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    max-width: 200px;
}

.rest-info-social {
    background-color: #000;
    color: #fff;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    &:hover {
        color: #fff;
    }
}

.rest-info-social-icon {
    position: relative;
    top: 3px; 

    &.facebook {
        left: 10px;
    }

    &.instagram {
        left: 8px;
    }

    &.twitter {
        top: 4px;
        left: 7px;
    }

    &.yelp {
        left: 9px;
    }
}


.rest-info-contact {
    padding: 20px 0; 
}

.hours {
    min-width: 150px;
}

.days {
    min-width: 100px;
}



@media screen and (max-width: 992px) {
    .rest-info-contain {
        padding: 0 2%;
    }

    .rest-info-content {
        align-items: center;
        text-align: center;
    }

    .tags-contain {
        justify-content: center;
    }

    .rest-info-icon {
        max-width: 100%;
        justify-content: center;
    }


}